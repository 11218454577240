import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Car from "./components/car/Car";
import Home from "./components/home/Home";
import Info from "./components/info/Info";
import Work from "./components/work/Work";
import Benefit from "./components/benefit/Benefit";
import Cars from "./components/car/Cars";
import { useEffect } from "react";
import AboutUs from "./components/about/AboutUs";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route
              path="/"
              element={
                <>
                  <div className="hero_area">
                    <Home />
                  </div>
                  <About />
                  <Benefit />
                  <Work />
                  <Car />
                  <div className="footer_bg">
                    <Info />
                  </div>
                </>
              }
            />
            <Route path="/cars" element={<Cars />} />
            <Route path="/about-us" element={<AboutUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
