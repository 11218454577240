import { useContext } from "react";
import { DataContext } from "../../DataContext";
import { TranslationContext } from "../../TranslationContext";
import "./Car.css";
function Cars() {
  const { fleet, getConf } = useContext(DataContext);
  const { getTrans } = useContext(TranslationContext);

  return (
    <div className="cars">
      <div className="container text-center car_section scroll-y ">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
          {fleet.map((f, i) => (
            <div key={i} className="col">
              <div className="card  border-0 shadow bg-body-tertiary rounded h-100">
                <div className="h-100 position-relative" data-aos="zoom-out">
                  <img src={f.image} className="card-img-top" alt="..." />
                  <span className="position-absolute bottom-0 end-0 badge rounded-pill bg-success fs-5 me-2">
                    {f.price + " " + getConf("currency")}
                  </span>
                </div>
                <div className="card-body" data-aos="zoom-out">
                  <h5 className="card-title fw-bold mb-3">{`${f.make} ${f.model} ${f.year}`}</h5>
                  <div className="row g-3">
                    <div className="col">
                      <i className="fs-2 bi bi-fuel-pump-fill"></i>
                      <br />
                      {getTrans(f.fuel_type)}
                    </div>
                    <div className="col">
                      <i className="fs-2 bi bi-people-fill"></i>
                      <br />
                      {f.n_seats}
                    </div>
                    <div className="col">
                      <i className="fs-2 bi bi-pin-map-fill"></i>
                      <br />
                      {getTrans(f.transmission)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Cars;
