import { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { DataContext } from "../../DataContext";
import "./About.css";
function AboutUs() {
  const { getTrans } = useContext(TranslationContext);
  const { getData } = useContext(DataContext);

  const about = getData("about");

  return (
    <section className="about_section about_us py-5">
      <div className="container pt-5 ">
        <div className="detail-box ">
          <div className="heading_container mt-5">
            <h2 className="text-white" data-aos="fade-up">
              {getTrans("About Us")}
              <i className="ms-2 bi bi-circle-fill fs-4"></i>
            </h2>
          </div>
          <p className="text-white" data-aos="fade-up">
            {about.split("\n").map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
