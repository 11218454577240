import { createContext, useContext, useEffect, useRef, useState } from "react";
import data from "./entreprise/data.json";
import { TranslationContext } from "./TranslationContext";
const DataContext = createContext();
const DataProvider = ({ children }) => {
  const { lang } = useContext(TranslationContext);
  const [isMobile, setIsMobile] = useState(false);
  const checkIsMobile = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    setIsMobile(width < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", checkIsMobile);
    checkIsMobile();
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  const sectionRefs = useRef([]);
  const addSectionRef = (ref) => {
    if (ref && !sectionRefs.current.includes(ref)) {
      sectionRefs.current.push(ref);
    }
  };
  const getData = (key) => {
    return data[lang()][key] ?? key;
  };
  const fleet = data["fleet"];
  const getConf = (key) => {
    return data[key] ?? key;
  };
  return (
    <DataContext.Provider
      value={{ getData, fleet, getConf, addSectionRef, sectionRefs, isMobile }}
    >
      {children}
    </DataContext.Provider>
  );
};
export { DataContext, DataProvider };
