import { useEffect } from "react";
import "./Carousel.css";
import toyota from "../../assets/images/toyota.png";
import bmw from "../../assets/images/bmw.png";
//import audi from "../../assets/images/audi.png";
import citroen from "../../assets/images/citroen.png";
import dacia from "../../assets/images/dacia.png";
import fiat from "../../assets/images/fiat.png";
import ford from "../../assets/images/ford.png";
//import honda from "../../assets/images/honda.png";
import hyundai from "../../assets/images/hyundai.png";
//import jeep from "../../assets/images/jeep.png";
//import kia from "../../assets/images/kia.png";
//import mercedes from "../../assets/images/mercedes.png";
import nissan from "../../assets/images/nissan.png";
//import opel from "../../assets/images/opel.png";
import peugeot from "../../assets/images/peugeot.png";
//import seat from "../../assets/images/seat.png";
//import suzuki from "../../assets/images/suzuki.png";
import vols from "../../assets/images/vols.png";
function Carousel() {
  const radius = 240; // how big of the radius
  const autoRotate = true; // auto rotate or not
  const rotateSpeed = -60; // unit: seconds/360 degrees
  const imgWidth = 130; // width of images (unit: px)
  const imgHeight = 180; // height of images (unit: px)

  useEffect(() => {
    const odrag = document.getElementById("drag-container");
    const ospin = document.getElementById("spin-container");
    const aImg = ospin.getElementsByClassName("img");
    const aEle = [...aImg]; // combine 2 arrays

    ospin.style.width = imgWidth + "px";
    ospin.style.height = imgHeight + "px";

    const ground = document.getElementById("ground");
    ground.style.width = radius * 3 + "px";
    ground.style.height = radius * 3 + "px";

    const init = (delayTime) => {
      for (let i = 0; i < aEle.length; i++) {
        aEle[i].style.transform =
          "rotateY(" +
          i * (360 / aEle.length) +
          "deg) translateZ(" +
          radius +
          "px)";
        aEle[i].style.transition = "transform 1s";
        aEle[i].style.transitionDelay =
          delayTime || (aEle.length - i) / 4 + "s";
      }
    };

    const applyTranform = (obj) => {
      if (tY > 180) tY = 180;
      if (tY < 0) tY = 0;
      obj.style.transform = "rotateX(" + -tY + "deg) rotateY(" + tX + "deg)";
    };

    const playSpin = (yes) => {
      ospin.style.animationPlayState = yes ? "running" : "paused";
    };

    let sX,
      sY,
      nX,
      nY,
      desX = 0,
      desY = 0,
      tX = 0,
      tY = 10;

    if (autoRotate) {
      const animationName = rotateSpeed > 0 ? "spin" : "spinRevert";
      ospin.style.animation = `${animationName} ${Math.abs(
        rotateSpeed
      )}s infinite linear`;
    }

    const handlePointerDown = (e) => {
      clearInterval(odrag.timer);
      e = e || window.event;
      sX = e.clientX;
      sY = e.clientY;

      const handlePointerMove = (e) => {
        e = e || window.event;
        nX = e.clientX;
        nY = e.clientY;
        desX = nX - sX;
        desY = nY - sY;
        tX += desX * 0.1;
        tY += desY * 0.1;
        applyTranform(odrag);
        sX = nX;
        sY = nY;
      };

      const handlePointerUp = (e) => {
        odrag.timer = setInterval(function () {
          desX *= 0.95;
          desY *= 0.95;
          tX += desX * 0.1;
          tY += desY * 0.1;
          applyTranform(odrag);
          playSpin(false);
          if (desX < 0.01 && desX > -0.01 && desY < 0.01 && desY > -0.01) {
            clearInterval(odrag.timer);
            playSpin(true);
          }
        }, 17);
        document.removeEventListener("mousemove", handlePointerMove);
        document.removeEventListener("mouseup", handlePointerUp);
      };
      document.addEventListener("mousemove", handlePointerMove);
      document.addEventListener("mouseup", handlePointerUp);
    };

    init();
  }, []);
  return (
    <div id="drag-container">
      <div id="spin-container">
        <div className="img">
          <img src={toyota} alt="" />
        </div>
        <div className="img">
          <img src={bmw} alt="" />
        </div>
        <div className="img">
          <img src={citroen} alt="" />
        </div>
        <div className="img">
          <img src={dacia} alt="" />
        </div>
        <div className="img">
          <img src={fiat} alt="" />
        </div>
        <div className="img">
          <img src={ford} alt="" />
        </div>
        <div className="img">
          <img src={nissan} alt="" />
        </div>
        <div className="img">
          <img src={vols} alt="" />
        </div>
        <div className="img">
          <img src={peugeot} alt="" />
        </div>
        <div className="img">
          <img src={hyundai} alt="" />
        </div>
      </div>
      <div id="ground"></div>
    </div>
  );
}

export default Carousel;
