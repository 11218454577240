import { useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import { DataContext } from "../../DataContext";
import { TranslationContext } from "../../TranslationContext";
import "./Car.css";
function Car() {
  const { fleet, addSectionRef, getConf, isMobile } = useContext(DataContext);
  const { getTrans } = useContext(TranslationContext);
  return (
    <section
      ref={addSectionRef}
      id="cars"
      className="car_section layout_padding-bottom"
    >
      <div className="container">
        <div className="heading_container mb-3">
          <h2 data-aos="fade-up" data-aos-anchor-placement="center-bottom">
            {getTrans("Vehicles")}{" "}
            <i className="ms-2 bi bi-circle-fill fs-4"></i>
          </h2>
          <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
            {getTrans("Our fleet s")}
          </p>
        </div>
      </div>
      <div className="container text-center mt-4">
        {!isMobile && (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
            {fleet.slice(0, 8).map((f, i) => (
              <div key={i} className="col">
                <div className="card  border-0 shadow bg-body-tertiary rounded h-100">
                  <div className="h-100 position-relative" data-aos="zoom-in">
                    <img src={f.image} className="card-img-top" alt="..." />
                    <span className="position-absolute bottom-0 end-0 badge rounded-pill bg-success fs-5 me-2">
                      {f.price + " " + getConf("currency")}
                    </span>
                  </div>
                  <div className="card-body" data-aos="zoom-in">
                    <h5 className="card-title fw-bold mb-3">{`${f.make} ${f.model} ${f.year}`}</h5>
                    <div className="row g-3">
                      <div className="col">
                        <i className="fs-2 bi bi-fuel-pump-fill"></i>
                        <br />
                        {getTrans(f.fuel_type)}
                      </div>
                      <div className="col">
                        <i className="fs-2 bi bi-people-fill"></i>
                        <br />
                        {f.n_seats}
                      </div>
                      <div className="col">
                        <i className="fs-2 bi bi-pin-map-fill"></i>
                        <br />
                        {getTrans(f.transmission)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {isMobile && (
          <Carousel indicators={false}>
            {fleet.slice(0, 6).map((f, i) => (
              <Carousel.Item key={i}>
                <div className="card  border-0 shadow bg-body-tertiary rounded h-100">
                  <div className="h-100 position-relative" data-aos="zoom-in">
                    <img src={f.image} className="card-img-top" alt="..." />
                    <span className="position-absolute bottom-0 end-0 badge rounded-pill bg-success fs-5 me-2">
                      {f.price + " " + getConf("currency")}
                    </span>
                  </div>
                  <div className="card-body" data-aos="zoom-in">
                    <h5 className="card-title fw-bold mb-3">{`${f.make} ${f.model} ${f.year}`}</h5>
                    <div className="row g-3">
                      <div className="col">
                        <i className="fs-2 bi bi-fuel-pump-fill"></i>
                        <br />
                        {getTrans(f.fuel_type)}
                      </div>
                      <div className="col">
                        <i className="fs-2 bi bi-people-fill"></i>
                        <br />
                        {f.n_seats}
                      </div>
                      <div className="col">
                        <i className="fs-2 bi bi-pin-map-fill"></i>
                        <br />
                        {getTrans(f.transmission)}
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
        <a className="mt-5" data-aos="fade-up" id="more" href="/cars">
          {getTrans("Read more")}
        </a>
      </div>
    </section>
  );
}

export default Car;
