import React, { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { DataContext } from "../../DataContext";

import "./Home.css";
import Carousel from "../carousel/Carousel";

function Home() {
  const { getTrans } = useContext(TranslationContext);
  const { getData, addSectionRef, isMobile } = useContext(DataContext);

  return (
    <section ref={addSectionRef} id="home" className=" slider_section ">
      <div className="row justify-content-center">
        <div className="col-xl-4 col-lg-6 col-md-12 px-xl-5 px-lg-5 px-md-5 px-1">
          <div className="detail_box text-lg-start text-center">
            <h1 data-aos="fade-up">{getData("title")}</h1>
            <p data-aos="fade-right">{getData("subtitle")}</p>
            <div data-aos="fade-up" className="btn-box">
              <a
                href={
                  isMobile
                    ? "whatsapp://send?phone=212658483827"
                    : "https://api.whatsapp.com/send/?phone=212658483827"
                }
                className="btn-1"
                target="_blank"
              >
                {getTrans("Booking")}
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 px-xl-5 px-lg-5 px-md-2 px-1 crs d-none d-md-block">
          <div data-aos="fade-left" className="img-box">
            <Carousel />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
