import "./Info.css";
import logo from "../../entreprise/logo.svg";
import { useContext } from "react";
import { DataContext } from "../../DataContext";
import { TranslationContext } from "../../TranslationContext";
function Info() {
  const { getConf, addSectionRef, getData, isMobile } = useContext(DataContext);
  const { getTrans } = useContext(TranslationContext);
  const about = getData("about");

  return (
    <>
      <iframe
        title="map"
        src={getConf("location")}
        width="100%"
        height="450"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <section
        ref={addSectionRef}
        id="contact_us"
        className="info_section layout_padding2"
      >
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <div className="info_logo">
                <img width={200} alt={getConf("name")} src={logo} />
                <p>{about.slice(0, about.indexOf(".") + 1)}</p>
              </div>
            </div>
            <div className="col-md-5 mt-5 mt-md-0">
              <div className="info_contact">
                <h4 className="fw-bold text-nowrap">
                  {getTrans("Contact us")}
                </h4>

                <a
                  href={
                    isMobile
                      ? "whatsapp://send?phone=212658483827"
                      : "https://api.whatsapp.com/send/?phone=212658483827"
                  }
                  target="_blank"
                >
                  <div className="img-box">
                    <i className="bi bi-telephone-fill fs-4 text-white"></i>
                  </div>
                  <p>{getConf("phone")}</p>
                </a>
                <a href={"mailto:" + getConf("email")}>
                  <div className="img-box">
                    <i className="bi bi-envelope-at-fill fs-4 text-white"></i>
                  </div>
                  <p>{getConf("email")}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid footer_section">
        <div className="container">
          <p>
            &copy; <span id="displayYear"></span> 2023{" "}
            <a href="#contact_us"> {getConf("name")}</a>.{" "}
            {getTrans("All Rights Reserved")}.
          </p>
        </div>
      </section>
    </>
  );
}

export default Info;
