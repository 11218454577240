import { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { DataContext } from "../../DataContext";
import "./About.css";
import h_img from "../../assets/images/h_img.svg";
function About() {
  const { getTrans } = useContext(TranslationContext);
  const { getData, addSectionRef } = useContext(DataContext);

  const about = getData("about");

  return (
    <section ref={addSectionRef} id="about" className="about_section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="img-box mt-sm-5 mt-md-0">
              <img data-aos="fade-right" src={h_img} alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="detail-box">
              <div className="heading_container mt-3 mt-md-5">
                <h2 data-aos="fade-up">
                  {getTrans("About Us")}
                  <i className="ms-2 bi bi-circle-fill fs-4"></i>
                </h2>
              </div>
              <p data-aos="fade-up">{about.slice(0, about.indexOf(".") + 1)}</p>
              <a data-aos="fade-up" href="/about-us">
                {getTrans("Read more")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
